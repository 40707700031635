import { update as intercomUpdate } from '@intercom/messenger-js-sdk';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type Credentials } from '@/types/auth';
import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';
import { dateCreatedFromMongoId } from '@/utils/dateCreatedFromMongoId';
import { addNewUserToHubspot } from '@/utils/hubspot';
import { getEmbeddedId, getReferral } from '@/utils/querystrings';

async function signUp({ credentials }: { credentials: Credentials }) {
  const { data } = await axios.post<{ session: Session }>(
    `/v1/dashboard/accounts/sign-up`,
    {
      ...credentials,
      referral: getReferral(),
      embeddedId: getEmbeddedId()
    },
    { withCredentials: true }
  );

  return data.session;
}

export function useSignUpMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: signUp,
    onSuccess: (data: Session) => {
      addNewUserToHubspot(data.user.email);
      queryClient.setQueryData([queryKeys.auth.session], data);
      intercomUpdate({
        signed_up_at: dateCreatedFromMongoId(data.account.id)
      });
    }
  });
}
