import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ThemeProviderContext } from '@knack/asterisk-react';

import { type SampleApp } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSession } from '@/hooks/useSession';
import { useSetupWizardLogic } from '@/hooks/useSetupWizardLogic';
import { shouldSendAnalytics } from '@/utils/analytics';
import { FullPageSpinner } from '@/components/ui/FullPageSpinner';
import { AiModal } from '@/pages/apps/create-app/ai-form/AiModal';
import { AppCreationErrorBanner } from '@/pages/apps/create-app/setup-wizard-variations/AppCreationErrorBanner';
import {
  getTemplateAppsFilteredAndSorted,
  trackEventAiClicked,
  trackEventImportAppCreated
} from '@/pages/apps/create-app/setup-wizard-variations/helper';
import { TemplateCard } from './TemplateCard';

interface StepSelectCardProps {
  selectedIndustry: string;
  selectedUseCase: string;
  sampleApps: SampleApp[];
  onBack: () => void;
  aiCardFlagValue?: 'personalized' | 'generate_ai';
}

export function StepSelectCard({
  selectedIndustry,
  selectedUseCase,
  sampleApps,
  onBack,
  aiCardFlagValue
}: StepSelectCardProps) {
  const [t] = useTranslation();
  const session = useSession();
  const { createAppFromSample } = useAppMutation();

  const [showAllTemplates, setShowAllTemplates] = useState(false);
  const { isDarkMode } = useContext(ThemeProviderContext);

  const templatesToDisplay = useMemo(
    () =>
      getTemplateAppsFilteredAndSorted(selectedIndustry, undefined, sampleApps, showAllTemplates),
    [selectedIndustry, sampleApps, showAllTemplates]
  );
  const {
    handleSampleCreateApp,
    handleAppCreation,
    selectedTemplate,
    setSelectedTemplate,
    appCreationError,
    shouldShowAiModal,
    setShouldShowAiModal,
    isPending
  } = useSetupWizardLogic(selectedIndustry, selectedUseCase, sampleApps);

  if (isPending) {
    return <FullPageSpinner />;
  }

  return (
    <div className="flex flex-col items-center gap-6 px-4 py-6" data-testid="step-select-card">
      {appCreationError && (
        <AppCreationErrorBanner
          appCreationError={appCreationError}
          data-testid="app-creation-error-banner"
        />
      )}
      <div className="mx-auto grid max-w-screen-lg grid-cols-1 gap-6 pb-16 sm:grid-cols-2 lg:grid-cols-3">
        {templatesToDisplay.map((template) => (
          <TemplateCard
            data-testid={`${template.slug}-card`}
            key={template.id}
            type="template"
            template={template}
            selectedTemplateId={selectedTemplate?.id}
            onClick={() => setSelectedTemplate(template)}
          />
        ))}
        <TemplateCard
          type="customize"
          data-testid="customize-card"
          selectedUseCase={selectedUseCase}
          aiCardFlagValue={aiCardFlagValue}
          onClick={async () => {
            if (shouldSendAnalytics(session.user.email)) {
              trackEventAiClicked(session.account.id);
            }
            void handleAppCreation('ai');
          }}
          isDarkMode={isDarkMode}
        />
        <TemplateCard
          type="import"
          isDarkMode={isDarkMode}
          onClick={() => {
            if (shouldSendAnalytics(session.user.email)) {
              trackEventImportAppCreated(session.account.id);
            }
            void handleAppCreation('import');
          }}
        />
        {templatesToDisplay.length <= 3 && !showAllTemplates && (
          <TemplateCard
            data-testid="more-templates-card"
            type="more"
            selectedIndustry={selectedIndustry}
            onClick={() => setShowAllTemplates(true)}
          />
        )}
      </div>

      <div className="fixed bottom-0 flex h-16 w-full items-center justify-end bg-base px-7 shadow-sm">
        <Button intent="minimal" onClick={onBack} data-testid="back-button">
          {t('actions.back')}
        </Button>
        <Button
          disabled={!selectedTemplate || createAppFromSample.isPending}
          onClick={handleSampleCreateApp}
          isLoading={createAppFromSample.isPending}
          className="ml-4"
          data-testid="create-app-button"
        >
          {t('components.create_app.create_app')}
        </Button>
      </div>

      {shouldShowAiModal && (
        <AiModal open onClose={() => setShouldShowAiModal(false)} data-testid="ai-modal" />
      )}
    </div>
  );
}
