import flagsmith from 'flagsmith';

import { type Session } from '@/types/session';
import { LOCAL_STORAGE_KEYS } from '@/utils/constants';
import { trackExperiment } from './analytics';

interface FlagsmithKeys {
  albato_flows: boolean;
  setup_wizard_v1: 'two_cta' | 'three_cta' | 'vertical_stack_ui';
  gt_setup_wizard_ai_button: 'personalized' | 'generate_ai';
  full_nextgen_access: boolean;
  int_maximum_ai_prompt_char_limit: number;
}

interface TrackVariationProps {
  experimentId: string;
  experimentName?: string;
  variationId: string;
  variationMapping?: Record<string, string>;
  accountId: string;
  userEmail: string;
  shouldSendAnalytics: (email: string) => boolean;
}

interface VariationPayload {
  experimentId: string;
  variationId: string;
  experimentName?: string;
  variationName?: string;
}

/**
 * Get the value of a flag
 */
export const getFlagValue = <T extends keyof FlagsmithKeys>(key: T) =>
  // We are assuming the Flagsmith key follows the type defined in FlagsmithKeys for this key
  flagsmith.getValue(key) as FlagsmithKeys[T];

/**
 * Return true if the flag is enabled
 */
export const isFlagEnabled = <T extends keyof FlagsmithKeys>(key: T) => flagsmith.hasFeature(key);

/**
 * Retrieves all enabled feature flags from Flagsmith
 */
export const getAllEnabledFlags = async <T extends keyof FlagsmithKeys>(): Promise<
  Partial<Record<keyof FlagsmithKeys, FlagsmithKeys[T]>>
> => {
  // This line ensures we're up-to-date with the flags value, meaning that if we previously identified the user we
  // ensure we get computed values for that identity and not control environment values.
  await flagsmith.getFlags();

  const flagsmithFlags = flagsmith.getAllFlags();

  return Object.entries(flagsmithFlags).reduce((acc, [name, { enabled, value }]) => {
    if (enabled) {
      acc[name] = value ?? enabled;
    }
    return acc;
  }, {});
};

/**
 * This function uses the localStorage and random ids to identify anonymous users in
 * Flagsmith to later obtain an identity-computed value.
 */
export const getAnonymousFlagValue = async <T extends keyof FlagsmithKeys>(key: T) => {
  if (flagsmith.getContext().identity) {
    // Ensure up-to-date state from Flagsmith
    await flagsmith.getFlags();

    return getFlagValue(key);
  }

  let id = localStorage.getItem(LOCAL_STORAGE_KEYS.anonymousFlagsId);

  if (id === null) {
    id = Math.floor(Date.now() * Math.random()).toString(16);

    localStorage.setItem(LOCAL_STORAGE_KEYS.anonymousFlagsId, id);
  }

  await flagsmith.setContext({ identity: { identifier: id } });

  // Ensure up-to-date state from Flagsmith
  await flagsmith.getFlags();

  return getFlagValue(key);
};

export const trackVariation = async ({
  experimentId,
  experimentName,
  variationId,
  variationMapping,
  accountId,
  userEmail,
  shouldSendAnalytics
}: TrackVariationProps) => {
  if (shouldSendAnalytics(userEmail)) {
    const variationName = variationMapping ? variationMapping[variationId] : undefined;
    const payload: VariationPayload = {
      experimentId,
      variationId
    };

    if (variationName) {
      payload.variationName = variationName;
    }

    if (experimentName) {
      payload.experimentName = experimentName;
    }
    await trackExperiment(payload, accountId);
  }
};

// Tracks the current `setContext` promise to prevent overlapping calls. If `setContext` is called while
// an existing call is still in progress, the second call would resolve prematurely, causing unexpected
// state issues. By ensuring `setContextPromise` completes before any new calls, we can avoid this premature
// resolution. This adjustment is necessary because the `Flagsmith` core library does not natively queue or
// prevent overlapping asynchronous calls.
let setContextPromise: Promise<void> | null = null;

export const ensureFlagsmithIdentity = async (session: Session) => {
  if (flagsmith.getContext().identity?.identifier === session.account.id) {
    return setContextPromise;
  }

  if (!setContextPromise) {
    setContextPromise = flagsmith
      .setContext({
        identity: {
          identifier: session.account.id
        }
      })
      .finally(() => {
        setContextPromise = null;
      });
  }

  return setContextPromise;
};
