import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type SampleApp } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getErrorMessage } from '@/utils/errors';
import { isFlagEnabled } from '@/utils/flagsmith';
import { getSampleWelcomePath } from '@/utils/samples';
import { AppCreateType, BUILDER_NEXT_IMPORT_PATH } from '@/pages/apps/create-app/constants';
import {
  getTemplateAppsFilteredAndSorted,
  trackEventImportAppCreated,
  trackEventSampleAppCreated
} from '@/pages/apps/create-app/setup-wizard-variations/helper';

export function useSetupWizardLogic(
  selectedIndustry: string,
  selectedUseCase: string,
  sampleApps: SampleApp[]
) {
  const [t] = useTranslation();
  const session = useSession();
  const { createAppFromSample, createApp } = useAppMutation();
  const [selectedTemplate, setSelectedTemplate] = useState<SampleApp>();
  const [shouldShowAiModal, setShouldShowAiModal] = useState(false);
  const [appCreationError, setAppCreationError] = useState('');

  // We'll need this instead of the isPending from the mutation because otherwise
  // the full page spinner will go for a few seconds before redirecting to the new app
  // as the mutation is already done
  const [isAppLoading, setIsAppLoading] = useState(false);
  const templatesToDisplay = useMemo(
    () => getTemplateAppsFilteredAndSorted(selectedIndustry, selectedUseCase, sampleApps),
    [selectedIndustry, sampleApps]
  );

  const handleSampleCreateApp = () => {
    if (!selectedTemplate) return;
    setIsAppLoading(true);
    const sampleData = {
      appId: selectedTemplate.id,
      templateSlug: selectedTemplate.slug,
      appDescription: selectedTemplate.description
    };
    createAppFromSample.mutate(sampleData, {
      onSuccess: (response) => {
        if (shouldSendAnalytics(session.user.email)) {
          trackEventSampleAppCreated(
            'Setup Wizard: New App From Sample Created',
            selectedTemplate.slug,
            session.account.id,
            t,
            selectedIndustry,
            selectedUseCase
          );
        }
        const nextgenAccess = isFlagEnabled('full_nextgen_access');
        const redirectUrl = nextgenAccess
          ? `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${session.account.slug}/${response.app.slug}/${getSampleWelcomePath(selectedTemplate)}`
          : `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${response.app.slug}/${getSampleWelcomePath(selectedTemplate)}`;

        window.location.href = redirectUrl;
      },
      onError: (error) => {
        setIsAppLoading(false);
        setAppCreationError(
          getErrorMessage(error, t('components.create_app.create_app_error_message'))
        );
      }
    });
  };

  const handleAppCreation = (caseType: 'import' | 'ai') => {
    if (caseType === 'ai') {
      setShouldShowAiModal(true);
      return;
    }

    if (caseType === 'import') {
      setIsAppLoading(true);
      const appData = {
        appDescription: '',
        appOrigin: AppCreateType.Import,
        shouldCreateWithTable: true,
        shouldCreateDefaultUserRoles: true
      };

      createApp.mutate(appData, {
        onSuccess: (response) => {
          if (caseType === 'import') {
            if (shouldSendAnalytics(session.user.email)) {
              trackEventImportAppCreated(session.account.id);
            }
          }
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${session.account.slug}/${response.application.slug}/${BUILDER_NEXT_IMPORT_PATH}?new=true`;
        },
        onError: (error) => {
          setIsAppLoading(false);
          setAppCreationError(
            getErrorMessage(error, t('components.create_app.create_app_error_message'))
          );
        }
      });
    }
  };

  return {
    templatesToDisplay,
    handleSampleCreateApp,
    handleAppCreation,
    selectedTemplate,
    setSelectedTemplate,
    appCreationError,
    setAppCreationError,
    shouldShowAiModal,
    setShouldShowAiModal,
    isPending: isAppLoading
  };
}
