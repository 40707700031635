import { useTranslation } from 'react-i18next';
import { HiDatabase as DatabaseIcon } from 'react-icons/hi';
import { HiMiniSquaresPlus as CustomDataIcon } from 'react-icons/hi2';
import { Button, Card } from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import { cn } from '@/utils/tailwind';
import { trackEventImportAppCreated } from '@/pages/apps/create-app/setup-wizard-variations/helper';

type SetupWizardSectionProps = {
  handleTestOrImportData: (caseType: string) => void;
};

type SetupWizardActionCardProps = {
  icon: React.ReactElement;
  descriptionKey: string;
  buttonTextKey: string;
  handleClick: () => void;
  buttonTestId: string;
};

function SetupWizardActionCard({
  icon,
  descriptionKey,
  buttonTextKey,
  handleClick,
  buttonTestId
}: SetupWizardActionCardProps) {
  const [t] = useTranslation();

  return (
    <Card className="max-h-22 overflow-hidden !p-0 group-hover:bg-subtle">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div
            className={cn('flex size-24 items-center justify-center bg-brand-50 p-6', {
              'bg-illustration-yellow': icon.type !== CustomDataIcon
            })}
          >
            {icon}
          </div>
          <div className="ml-4 text-sm">{t(descriptionKey)}</div>
        </div>
        <div className="mr-6 flex items-center gap-2">
          <Button
            intent="link"
            size="sm"
            data-testid={buttonTestId}
            className={cn('text-sm', {
              'textgradient-1': icon.type === CustomDataIcon
            })}
            onClick={(event) => {
              event.stopPropagation();
              handleClick();
            }}
          >
            {t(buttonTextKey)}
          </Button>
        </div>
      </div>
    </Card>
  );
}

export function SetupWizardSection({ handleTestOrImportData }: SetupWizardSectionProps) {
  const session = useSession();
  const handleEvent = async (event: string) => {
    if (shouldSendAnalytics(session.user.email)) {
      switch (event) {
        case 'ai':
          await rudderStackAnalytics.track('Setup Wizard: Customize My App/AI clicked', {
            groupId: session.account.id
          });
          break;
        case 'import':
          trackEventImportAppCreated(session.account.id);
          break;
        default:
          break;
      }
    }
  };
  return (
    <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
      <SetupWizardActionCard
        icon={<CustomDataIcon className="size-10 fill-[url(#svg-gradient-2)]" />}
        descriptionKey="components.setup_wizard.needs_unique"
        buttonTextKey="components.setup_wizard.custom_app_button"
        handleClick={async () => {
          await handleEvent('ai');

          handleTestOrImportData('ai');
        }}
        buttonTestId="three-cta-ai-button"
        data-testid="three-cta-ai-button"
      />
      <SetupWizardActionCard
        data-testid="three-cta-import-button"
        icon={<DatabaseIcon className="size-10 text-yellow-600" />}
        descriptionKey="components.setup_wizard.start_my_data"
        buttonTextKey="components.setup_wizard.import_data_button"
        handleClick={async () => {
          await handleEvent('import');

          handleTestOrImportData('import');
        }}
        buttonTestId="three-cta-import-button"
      />
    </div>
  );
}
