import { useState } from 'react';
import { useForm, type FieldValues, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { update as intercomUpdate } from '@intercom/messenger-js-sdk';
import { Banner, Button, Dialog, Label } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  IssueWithProductReasons,
  IssueWithSupportReasons,
  MovedToCompetitorReasons,
  ReasonsForDeleting,
  Route
} from '@/enums';
import { useFreezeInfo } from '@/hooks/account/useFreezeInfo';
import {
  useDeleteAccountMutation,
  type AccountDeletionFeedback
} from '@/hooks/api/mutations/useDeleteAccountMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { getErrorMessage } from '@/utils/errors';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { FreezeAccountModal } from '@/pages/settings/account/FreezeAccountModal';
import i18n from '@/i18n';
import { ExitSurvey } from './ExitSurvey';

function refineFields(reason: string, issue: string) {
  return (fieldValues: FieldValues) => {
    const isReasonForDeletionMatched = fieldValues.reasonForAccountDeletion === reason;
    const issueField = fieldValues[issue];

    if (!isReasonForDeletionMatched || issueField) {
      return {
        path: [issue],
        message: i18n.t('components.account.delete_modal.field_required')
      };
    }
    return null;
  };
}

const exitSurveySchema = z
  .object({
    reasonForAccountDeletion: z.nativeEnum(ReasonsForDeleting, {
      errorMap: () => ({
        message: i18n.t('components.account.delete_modal.select_field_required')
      })
    }),
    otherReason: z
      .string()
      .min(1, i18n.t('components.account.delete_modal.field_required'))
      .optional(),
    notGoodFitForUsReason: z
      .string()
      .min(1, i18n.t('components.account.delete_modal.field_required'))
      .optional(),
    otherCompetitor: z
      .string()
      .min(1, i18n.t('components.account.delete_modal.field_required'))
      .optional(),
    directFeedback: z.string().optional(),
    productIssue: z.nativeEnum(IssueWithProductReasons).optional(),
    issueAdditionalInformation: z.string().optional(),
    productIssueMissingFeature: z
      .string()
      .min(1, i18n.t('components.account.delete_modal.field_required'))
      .optional(),
    productIssueBugs: z.string().optional(),
    supportIssue: z.nativeEnum(IssueWithSupportReasons).optional(),
    supportIssueTicketID: z.string().optional(),
    otherCompetitorReason: z.nativeEnum(MovedToCompetitorReasons).optional(),
    otherCompetitorMissingFeatures: z
      .string()
      .min(1, i18n.t('components.account.delete_modal.field_required'))
      .optional()
  })
  .refine(refineFields(ReasonsForDeleting.IssueWithProduct, 'productIssue'))
  .refine(refineFields(ReasonsForDeleting.IssueWithSupport, 'supportIssue'))
  .refine(refineFields(ReasonsForDeleting.Competitor, 'otherCompetitorReason'));

export type ExitSurveySchemaType = z.infer<typeof exitSurveySchema>;

export function DeleteAccountModal() {
  const [t] = useTranslation();
  const { mutate: deleteAccount, isPending } = useDeleteAccountMutation();
  const { isFrozen, canFreeze, freezeCost } = useFreezeInfo();
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { search } = useLocation();
  const exitSurveyFormId = 'exit-survey-form';
  const { data: account } = useAccountQuery();
  const isTrial = account?.product_plan?.id === 'trial';
  const {
    register,
    handleSubmit,
    control,
    reset: resetExitSurveyForm,
    unregister,
    formState: { isSubmitting, isDirty, errors, isValid },
    watch: watchExitSurveyForm
  } = useForm<ExitSurveySchemaType>({
    resolver: zodResolver(exitSurveySchema)
  });

  const onModalClose = () => {
    resetExitSurveyForm();
  };

  const onSubmitHandler: SubmitHandler<ExitSurveySchemaType> = (
    accountDeletionFeedback: AccountDeletionFeedback
  ) => {
    deleteAccount(accountDeletionFeedback, {
      onSuccess: () => {
        intercomUpdate({
          churn_date: Math.floor(Date.now() / 1000)
        });
        navigate({
          pathname: `/${Route.AccountDeleted}`,
          search
        });
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.account.delete_modal.delete_error'));
        setErrorMessage(message);
      }
    });
  };

  const showHubspotQuickReview = () => {
    window.open('https://app.hubspot.com/meetings/23287346/link/6382139', '_blank');
  };

  const Footer = (
    <Dialog.Footer intent="borderless">
      <Dialog.Close asChild data-testid="delete-account-dialog-cancel-button">
        <Button intent="minimal">{t('actions.cancel')}</Button>
      </Dialog.Close>
      <Button
        data-testid="delete-account-dialog-delete-button"
        type="submit"
        form={exitSurveyFormId}
        isLoading={isPending}
        disabled={isSubmitting || !isDirty || !isValid}
        intent="destructive"
      >
        {t('components.account.delete_account')}
      </Button>
    </Dialog.Footer>
  );

  return (
    <Dialog>
      <Dialog.Trigger asChild>
        <Button data-testid="account-delete-your-account-button">
          {t('components.account.delete_account')}
        </Button>
      </Dialog.Trigger>
      <DashboardDialogContent
        data-testid="delete-account-dialog"
        headerTitle={t('components.account.delete_account')}
        headerDescription={t('components.account.delete_modal.confirm')}
        footer={Footer}
        onCloseAutoFocus={onModalClose}
      >
        <div className="flex flex-col gap-6 pt-6">
          <div className="flex flex-col gap-6 bg-default">
            {!isTrial && (
              <div className="flex flex-col gap-2">
                {!isFrozen && (
                  <Label>
                    {t('components.account.delete_modal.option_1')}
                    {t('components.account.delete_modal.option_review_title')}
                  </Label>
                )}
                <p>{t('components.account.delete_modal.option_review_info')}</p>

                <Button className="flex-0 self-start" onClick={showHubspotQuickReview}>
                  {t('components.account.delete_modal.get_a_review')}
                </Button>
              </div>
            )}

            {!isFrozen && (
              <div className="flex flex-col gap-2">
                <Label>
                  {isTrial
                    ? t('components.account.delete_modal.option_1')
                    : t('components.account.delete_modal.option_2')}
                  {t('components.account.delete_modal.option_freeze_title')}
                </Label>
                <p>{t('components.account.delete_modal.option_freeze_info')}</p>
                <div className="flex items-center gap-4">
                  <FreezeAccountModal disabled={!canFreeze} />
                  <p>
                    {t('components.account.freeze_cost', {
                      cost: freezeCost
                    })}
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-6 bg-default">
            <form
              id={exitSurveyFormId}
              className="flex flex-col gap-6"
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <ExitSurvey
                control={control}
                register={register}
                errors={errors}
                watchExitSurveyForm={watchExitSurveyForm}
                unregister={unregister}
              />
            </form>

            <div className="flex flex-col gap-2">
              <Label>{t('components.account.delete_modal.still_want_to_delete')}</Label>
              <p>{t('components.account.delete_modal.still_want_to_delete_info')}</p>
            </div>
          </div>
        </div>
        {errorMessage && (
          <Banner intent="destructive" icon={ExclamationIcon} className="my-6">
            <Banner.Message>{errorMessage}</Banner.Message>
          </Banner>
        )}
      </DashboardDialogContent>
    </Dialog>
  );
}
