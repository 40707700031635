import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Input,
  Label,
  MultiSelect,
  Select,
  ThemeProviderContext,
  type MultiSelectOption
} from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { cn } from '@/utils/tailwind';
import {
  SETUP_WIZARD_INDUSTRIES_USE_CASES,
  SETUP_WIZARD_STEPS,
  USE_CASES_FOR_CONCRETE_TEMPLATE
} from '@/pages/apps/create-app/constants';
import {
  goalsList,
  translationKeyPrefixVerticalUI
} from '@/pages/apps/create-app/setup-wizard-variations/constants';
import {
  getTitleFromFilter,
  trackIndustryUseCaseEvent,
  updateIntercomIndustryUseCase,
  type AnalyticsIndustryUseCaseInterface
} from '@/pages/apps/create-app/setup-wizard-variations/helper';

interface StepSelectIndustryVerticalUIProps {
  setCurrentStep: (step: string) => void;
  selectedIndustry: string | undefined;
  setSelectedIndustry: (value: string | undefined) => void;
  selectedUseCase: string | undefined;
  setSelectedUseCase: (value: string | undefined) => void;
  otherUseCaseDetails: string | undefined;
  setOtherUseCaseDetails: (value: string | undefined) => void;
  goals: MultiSelectOption[];
  setGoals: (value: MultiSelectOption[]) => void;
}

export function StepSelectIndustryVerticalUI({
  setCurrentStep,
  selectedIndustry,
  setSelectedIndustry,
  selectedUseCase,
  setSelectedUseCase,
  otherUseCaseDetails,
  setOtherUseCaseDetails,
  goals,
  setGoals
}: StepSelectIndustryVerticalUIProps) {
  const { industries } = SETUP_WIZARD_INDUSTRIES_USE_CASES;
  const useCases = USE_CASES_FOR_CONCRETE_TEMPLATE;
  const [t] = useTranslation();
  const session = useSession();
  const isUseCaseInputEmpty =
    selectedUseCase === 'other' && otherUseCaseDetails?.trim().length === 0;
  const [multiSelectOptions, setMultiSelectOptions] = useState<MultiSelectOption[]>(goalsList);
  const { isDarkMode } = useContext(ThemeProviderContext);
  return (
    <div
      className="flex w-full flex-col items-center justify-center"
      data-testid="step-select-industry"
    >
      <Card
        className={cn(
          'm-4 flex w-full max-w-[700px] flex-col flex-wrap items-start justify-center p-8 sm:w-3/4 lg:w-1/2',
          {
            'border border-brand-200 bg-brand-50 shadow-none': goals.length === 0 && !isDarkMode,
            'border border-brand-800 bg-brand-600 text-white shadow-none':
              goals.length === 0 && isDarkMode
          }
        )}
      >
        <div className="flex w-full flex-col">
          <div className="flex items-center gap-2 text-start text-lg sm:flex-row sm:text-left">
            <Trans i18nKey={`${translationKeyPrefixVerticalUI}.step_1.industry`}>
              <Select
                data-testid="industry-selector"
                onValueChange={(value) => {
                  setSelectedIndustry(value);
                  setOtherUseCaseDetails('');
                }}
                value={selectedIndustry}
              >
                <Select.Trigger
                  className="w-3/5"
                  size="lg"
                  placeholder={t(`${translationKeyPrefixVerticalUI}.step_1.select_industry`)}
                />
                <Select.Content className="min-w-[200px]">
                  {industries.map((industry) => (
                    <Select.Item
                      key={industry}
                      value={industry}
                      data-testid={`industry-${industry}`}
                    >
                      {getTitleFromFilter(t, industry)}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select>
            </Trans>
          </div>
          <div className="my-3 flex items-center gap-2 text-start text-lg sm:flex-row sm:text-left">
            <Trans i18nKey={`${translationKeyPrefixVerticalUI}.step_1.goals`}>
              <MultiSelect
                triggerClassName="w-2/5 text-sm"
                contentClassName="min-w-[300px]"
                itemClassName="w-full"
                isSelectAllEnabled={false}
                placeholder={t(
                  `${translationKeyPrefixVerticalUI}.step_1.${selectedIndustry ? 'select_goals' : 'choose_industry_first'}`
                )}
                id="goals"
                selectedOptions={goals}
                onSelectOptions={(values) => {
                  setGoals(values);

                  setMultiSelectOptions((prevOptions) =>
                    prevOptions.map((option) => ({
                      ...option,
                      isDisabled:
                        values.length >= 2 &&
                        !values.some((selected) => selected.key === option.key)
                    }))
                  );
                }}
                data-testid="goals-selector"
                options={multiSelectOptions}
                disabled={!selectedIndustry}
              />
            </Trans>
          </div>
        </div>
      </Card>

      <Card
        className={cn(
          'flex flex-col items-start justify-start p-6',
          {
            'text-muted': goals.length === 0,
            'border border-brand-200 bg-brand-50 shadow-none':
              goals.length >= 1 && !selectedUseCase && !isDarkMode,
            'border border-brand-800 bg-brand-600 text-white shadow-none':
              goals.length >= 1 && !selectedUseCase && isDarkMode
          },
          'w-full max-w-[700px] sm:w-3/4 lg:w-1/2'
        )}
      >
        <div className="w-full text-xl">
          <Trans i18nKey={`${translationKeyPrefixVerticalUI}.step_1.use_case.title`}>
            <strong>
              <i>first</i>
            </strong>
          </Trans>
        </div>

        <p className="text-sm text-muted">
          {t(`${translationKeyPrefixVerticalUI}.step_1.use_case.subtitle`)}
        </p>
        <div className="mb-2 mt-6 flex w-full flex-col items-start gap-2 text-left text-md sm:flex-row">
          <Select
            data-testid="use-case-selector"
            onValueChange={(value) => {
              setSelectedUseCase(value);
              if (value.toLowerCase() !== 'other') {
                setOtherUseCaseDetails(undefined);
              }
            }}
            value={selectedUseCase}
            disabled={!selectedIndustry || goals.length === 0}
          >
            <Select.Trigger
              className="w-3/5"
              size="lg"
              placeholder={t(`${translationKeyPrefixVerticalUI}.step_1.select_project`)}
            />
            <Select.Content className="w-2/5 min-w-[200px]">
              {useCases.map((useCase) => (
                <Select.Item
                  className="w-full"
                  key={useCase.key}
                  value={useCase.key}
                  data-testid={`use-case-${useCase.key}`}
                >
                  {t(`${translationKeyPrefixVerticalUI}.use_cases.${useCase.key}`)}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </div>
        {selectedIndustry && goals.length > 0 && selectedUseCase?.toLowerCase() === 'other' && (
          <Input.Container className="mt-4">
            <Label className="text-sm" data-testid="other-use-case-label">
              {t(`${translationKeyPrefixVerticalUI}.step_1.use_case.input`)}
            </Label>
            <Input
              required
              className="my-2 max-w-[500px]"
              data-testid="other-use-case-input"
              id="other-use-case-input"
              value={otherUseCaseDetails}
              title={t(`${translationKeyPrefixVerticalUI}.step_1.use_case.input`)}
              onChange={(e) => {
                setOtherUseCaseDetails(e.target.value);
              }}
            />
            {isUseCaseInputEmpty && (
              <span className="text-sm text-red-500" data-testid="use-case-error">
                {t(`${translationKeyPrefixVerticalUI}.step_1.use_case.input_error`)}
              </span>
            )}
          </Input.Container>
        )}
      </Card>

      <div className="align-end mt-4 flex w-full max-w-[700px] justify-end">
        <Button
          data-testid="next-button"
          disabled={
            !selectedIndustry || !selectedUseCase || isUseCaseInputEmpty || goals.length === 0
          }
          type="button"
          onClick={() => {
            const analyticsIndustryUseCaseBody: AnalyticsIndustryUseCaseInterface = {
              groupId: session.account.id,
              industry: selectedIndustry ?? '',
              intent: goals.map((goal) => goal.value).join(', '),
              useCase: t(`${translationKeyPrefixVerticalUI}.use_cases.${selectedUseCase}`) ?? '',
              other_details: (selectedUseCase === 'other' ? otherUseCaseDetails : '') ?? ''
            };
            if (shouldSendAnalytics(session.user.email)) {
              trackIndustryUseCaseEvent(analyticsIndustryUseCaseBody);
            }
            if (selectedIndustry && selectedUseCase) {
              void updateIntercomIndustryUseCase(
                getTitleFromFilter(t, selectedIndustry),
                t(`${translationKeyPrefixVerticalUI}.use_cases.${selectedUseCase}`),
                analyticsIndustryUseCaseBody.intent
              );
            }
            setCurrentStep(SETUP_WIZARD_STEPS[1]);
          }}
        >
          {t('actions.next')}
        </Button>
      </div>
    </div>
  );
}
